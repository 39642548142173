// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-disclaimer-jsx": () => import("./../../../src/pages/disclaimer.jsx" /* webpackChunkName: "component---src-pages-disclaimer-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-terms-general-terms-jsx": () => import("./../../../src/pages/terms/general-terms.jsx" /* webpackChunkName: "component---src-pages-terms-general-terms-jsx" */),
  "component---src-pages-terms-index-jsx": () => import("./../../../src/pages/terms/index.jsx" /* webpackChunkName: "component---src-pages-terms-index-jsx" */)
}

